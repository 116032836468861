export default {
  path: "/place",
  redirect: "/place/index",
  meta: {
    title: "音色位",
    icon: "ri:exchange-cny-fill",
    rank: 99,
    roles: ["1"]
  },
  children: [
    {
      path: "/place/index",
      name: "Code",
      component: () => import("@/views/place/index.vue"),
      meta: {
        title: "音色位"
      }
    }
  ]
} as RouteConfigsTable;
